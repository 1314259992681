import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import Validacion from "../components/pages/Validacion"
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const IndexPage = ({ pageContext, location }) => (
  <GlobalFooterProvider>
  <LayoutsComponent
    location={location}
    pageContext={pageContext}
    layout={pageContext.layout}
    broker={pageContext.broker}
    companyName={pageContext.company}
    gradient
    currentStep={pageContext.step}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <Validacion pageContext={pageContext} companyName={pageContext.company} />
      </>
    )}
  />
  </GlobalFooterProvider>
)

export default IndexPage
